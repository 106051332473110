import { ORIGIN } from "@config/base";
import { telegramSupportBotLink } from "@config/social";
import ru from "vuetify/src/locale/ru";

export default {
  ...ru,
  dictionary: {
    country: {
      tr: "Турция",
      ee: "Эстония",
      us: "США",
    },
  },
  warnings: {
    cards_suspended_update_status: `<b>Карты заморожены</b> - Чтобы продолжить работу с картами, снимите их со статуса "Заморожено".`,
    overdraft_admedia_prepaid_card:
      "Обратите внимание, карта может выйти в минус на {0} из-за комиссии за международные платежи!",
    overdraft_admedia_postpaid_card:
      "Обратите внимание, транзакции могут пройти выше указанного лимита на {0} из-за комиссии за международные платежи!",
  },
  product_tour: {
    prev_btn_text: "Назад",
    next_btn_text: "Далее",
    done_btn_text: "Завершить",
    progress_text: "{{current}} из {{total}}",
  },
  errors: {
    can_choose_max: "Макс. возможное кол-во - {0} шт.",
    promocode_not_valid: "Введенный промокод недействителен.",
    promocode_access_blocked:
      "Превышено максимальное количество неудачных попыток. Попробуйте позднее.",
    bank_inquiry_disabled: "Данный BIN отключен на вашем аккаунте.",
    invalid_invite: "Пригласительная ссылка недействительна или устарела.",
    internal_error: "Внутренняя ошибка",
    no_internet_connection: "Нет подключения к интернету",
    invalid_telegram_login:
      "Укажите, пожалуйста, логин в Telegram. Например, @example",
    invalid_current_password: "Текущий пароль неверный",
    invalid_promocode: "Промокод не существует или недействителен",
    limit_amount_card_issue:
      "Сумма лимита должна быть больше количества активных карт ({0})",
    positive_number: "Укажите положительное число",
    only_digits: "Укажите числовое значение",
    only_integer_number: "Укажите целое число",
    min_amount_refill: "Минимальная сумма пополнения составляет {0}",
    min_amount_transfer: "Минимальная сумма перевода составляет {0}",
    minimal_card_balance_cannot_be_less:
      "Минимальный остаток на карте не может быть меньше {0}",
    min_amount: "Минимальная сумма {0}",
    max_amount: "Максимальная сумма {0}",
    min_value: "Минимальное значение {0}",
    max_value: "Максимальное значение {0}",
    max_сhars: "Максимальное количество символов: {0}",
    try_later: "Произошла ошибка. Пожалуйста, попробуйте позднее",
    access_denied: "Ваши данные для входа неверны",
    user_already_exists: "Пользователь уже существует",
    invalid_code: "Неправильный код",
    too_many_postpaid_card_issued: `Вы достигли максимального количества лимитных карт. Обратитесь, пожалуйста, в нашу поддержку: <a href="${telegramSupportBotLink}" target="_blank">@multicards_support_bot</a>.`,
    too_many_card_issued_mediabuyer:
      "Вы достигли максимального количества активных карт. Обратитесь, пожалуйста, к своему Тимлиду для увеличения лимита.",
    too_many_card_issued_teamlead: `Вы достигли максимального количества активных карт. Обратитесь, пожалуйста, в нашу поддержку: <a href="${telegramSupportBotLink}" target="_blank">@multicards_support_bot</a>.`,
    bank_not_available_support: `Данный банк пока Вам недоступен. Обратитесь, пожалуйста, в нашу поддержку <a href="${telegramSupportBotLink}" target="_blank">@multicards_support_bot</a>.`,
    user_account_not_found: "Учетная запись пользователя не найдена",
    required: "Требуется для заполнения",
    email: {
      incorrect: "Неправильный адрес электронной почты",
      try_with_another_domain: "Пожалуйста, попробуйте e-mail с другим доменом",
    },
    password: {
      must_match: "Пароли должны совпадать",
      max_number_change_requests:
        "Превышено максимальное количество запросов на смену пароля. Попробуйте, пожалуйста, позднее.",
      length:
        "Длина пароля должна составлять от 8 до 20 латинских символов, в том числе одна цифра и одна заглавная буква",
    },
    insufficient_balance: "Недостаточно средств на балансе",
    insufficient_balance_user: "Недостаточно средств на счету пользователя {0}",
    image_size: "Размер изображения не должен превышать {0}кб",
    sms_confirmation_verify_failed: "Неправильный код подтверждения",
    sms_verification_max_attempts_error:
      "Вы достигли максимального количества попыток на смену номера телефона. Пожалуйста, попробуйте позднее",
    card: {
      max_limit_you_can_set:
        "Максимальный лимит, который вы можете установить {0}",
      limit_balance: "Вы должны иметь как минимум {0} на балансе",
      total_daily_limit:
        "Общий лимит должен быть больше или равен дневному лимиту.",
    },
    phone_number: {
      required: "Заполните номер телефона",
      busy_and_try_another:
        "Данный номер телефона не может использоваться на нашем сервисе. Пожалуйста, попробуйте ввести другой номер",
    },
  },
  info: {
    transferred_5_minutes: "{0} будут переведены в течение 5 минут!",
    promocode_successfully_applied: "Промокод успешно применен!",
    copy_to_clipboard: "Нажмите {0} для копирования",
    contact_support_get_api_doc:
      "Для получения API doc. обратитесь, пожалуйста, в поддержку.",
    set_limit_amount_success: "Лимит успешно установлен",
    changes_saved: "Ваши изменения были сохранены",
    password_changed: "Ваш пароль был успешно изменён",
    refill_user_success: "Баланс {0} пополнен на {1}",
    card_refilled: "Карта пополнена",
    sure_want_refill_card: "Уверены, что хотите пополнить карту на {0} ?",
    sure_want_refill_account:
      "Уверены, что хотите пополнить баланс пользователя {0} на {1} ?",
    copied: "Скопировано",
    copy: "Скопировать",
    copy_card_number: "Скопировать номер карты",
    card_created: "Карта успешно создана",
    download_to_csv: "Скачать в CSV",
    download_to_excel: "Скачать в EXCEL",
    deleted: "Удален",
    pay_attention_for_advertising_purposes: `
      Обратите внимание, что пользоваться картами можно только для оплаты рекламных кабинетов <span class="white--text">({0})</span>.
      При оплате сторонних сервисов аккаунт и денежные средства на нем будут заблокированы.
    `,
    commission_canceled_transactions_non_refundable:
      "Комиссия за отмененные транзакции не возвращается.",
    card_frozen_no_transactions_more_two_weeks:
      "Если на карте не было транзакций более 2-ух недель с момента выпуска, то карта замораживается. Для активной работы с картой лимиты требуется переставить вручную.",
    agree_to_receive_newsletters:
      "Я соглашаюсь получать новости, специальные предложения и другую информационную рассылку на указанный мной email.",
    declension: {
      cards: {
        i_1: "карта",
        i_2: "карты",
        i_3: "карт",
        i_4: "карту",
        close: {
          i_1: "закрыта",
          i_2: "закрыто",
          i_3: "закрыто",
        },
        freeze: {
          i_1: "поставлена на паузу",
          i_2: "поставлено на паузу",
          i_3: "поставлено на паузу",
        },
        unfreeze: {
          i_1: "снята с паузы",
          i_2: "снято с паузы",
          i_3: "снято с паузы",
        },
        unsuspend: {
          i_1: "разморожена",
          i_2: "разморожено",
          i_3: "разморожено",
        },
      },
    },
  },
  locked_page: {
    team: {
      text: `Раздел <b>"Команда"</b> пока Вам недоступен.<br />
        Для открытия данного раздела, обратитесь, пожалуйста, в нашу поддержку:`,
    },
    referral_program: {
      text: `Раздел <b>"Реферальная программа"</b> пока Вам недоступен.<br />
        Для открытия данного раздела, обратитесь, пожалуйста, в нашу поддержку:`,
    },
  },
  promocode: {
    type: {
      free_cards_bonus: "Free cards",
      deposit_bonus: "Deposit bonus",
    },
    status: {
      completed: "Использован",
      active: "Активный",
    },
  },
  team: {
    overall_balance: "Общий баланс команды",
    sure_want_member: "Вы уверены, что хотите удалить {0} из команды?",
    status: {
      active: "Активен",
      deleted: "Удален",
    },
  },
  telegramBanner: {
    title: "Присоединяйся к нам в telegram!",
    subtitle: "Будь в курсе свежих новостей <span>multicards.io</span>.",
  },
  messages: {
    facebookCode: {
      title: "{0} - код Facebook",
      text: "Данный код относится к оплате по карте {0}",
    },
    card3dsСode: {
      title: "3DS-код: {0}",
      text: "Номер карты: {0}<br/>Пароль в следующем окне: {1}",
    },
  },
  fields: {
    all: "Все",
    or: "или",
    to_be_paid: "К выплате",
    total_paid: "Всего выплачено",
    paid: "Выплачено",
    apply: "Применить",
    promocode_name: "Название промокода",
    mediabuyer: "Медиабайер",
    teamlead: "Тимлид",
    max_number_of_cards: "Макс. количество карт",
    out_of: "из",
    no_limit: "Без лимита",
    no_daily_limit: "Без дневного лимита",
    no_total_limit: "Без общего лимита",
    number_of_cards: "Количество карт",
    daily_limit: "Дневной лимит",
    total_limit: "Общий лимит",
    limit: "Лимит",
    deposit_amount: "Сумма пополнения",
    deposit_amounts: "Сумма пополнений",
    deposit_amount_per_card: "Сумма пополнения на карту",
    card_name: "Название карты",
    promocode: "Промокод",
    history_promocodes: "История промокодов",
    min_commission: "Мин. комиссия",
    commission_rate: "Комиссия",
    card_issue: "Выпуск карты",
    confirmation_code: "Код подтверждения",
    firstName: "Имя",
    lastName: "Фамилия",
    password: "Пароль",
    new_password: "Новый пароль",
    repeat_new_password: "Повторите новый пароль",
    confirm_password: "Повторите пароль",
    forgot_password: "Забыли пароль?",
    continue: "Продолжить",
    back: "Назад",
    phone: "Номер телефона",
    notification: "Уведомление",
    cancel: "Отменить",
    next: "Далее",
    save_changes: "Сохранить изменения",
    change: "Изменить",
    connect: "Подключить",
    disable: "Отключить",
    logout: "Выйти",
    auth_2auth: "2FA аутентификация",
    your_password: "Ваш пароль",
    current_password: "Текущий пароль",
    code_2fa: "2FA-код",
    notifications: "Уведомления",
    login_google: "Войти с Google",
    sign_up_google: "Регистрация через Google",
  },
  error_page: {
    iternal_server_error: {
      subtitle: "Внутренняя ошибка сервера",
      text: "Сервер обнаружил внутреннюю ошибку или неправильную конфигурацию и не смог выполнить ваш запрос",
    },
    page_not_found: {
      subtitle: "Страница не найдена",
      text: "Страница, которую вы ищете, могла быть удалена из-за изменения названия или временно недоступна",
    },
    service_unavailable: {
      subtitle: "Система на обслуживании",
      text: "Мы устраняем возникшие неполадки. В ближайшее время работа сервиса восстановится",
    },
    back_to_home: "Вернуться на главную",
  },
  cookies: {
    text1:
      "У вас есть возможность принять или отклонить файлы cookie. Большинство веб-браузеров автоматически принимают файлы cookie, но вы обычно можете изменить настройки своего браузера, чтобы отказаться от файлов cookie, если хотите. Однако обратите внимание, что отключение файлов cookie может повлиять на вашу возможность доступа к определенным функциям Веб-сайта.",
    text2:
      "Чтобы управлять своими предпочтениями в отношении файлов cookie, вы можете изменить настройки своего браузера или использовать инструмент управления согласием на использование файлов cookie, доступных на нашем веб-сайте.",
    accept: "Принять",
  },
  landing: {
    nav: {
      benefits: "Преимущества",
      contact_us: "Связаться с нами",
      get_started: "Начать",
      log_in: "Войти",
      sign_up: "Зарегистрироваться",
    },
    into: {
      title: "Виртуальные карты <br />для",
      subtitle:
        "<b>Простое</b> и <b>идеальное</b> решение для онлайн-маркетинга и электронной коммерции.",
      learn_more: "Узнать больше",
      list: {
        item1: {
          title: "Низкие цены",

          subtitle: `
              <ul>
                <li>Больше трат - меньше комиссий.</li>
                <li>Безлимитные карты.</li>
                <li>Пополняйте криптовалютой и переводами WIRE.</li>
              <ul>
            `,
        },
        item2: {
          title: "Работайте со своей командой",
          subtitle:
            "Добавляй членов своей команды и управляй картами и бюджетами.",
        },
        item3: {
          title: "Систематическая аналитика",
          subtitle:
            "Получайте персональные отчеты и детализированную статистику по расходам для каждого члена команды.",
        },
      },
    },
    get_started: {
      title: "<strong>Multicards</strong> - это просто и удобно",
      text1:
        "Когда ваш аккаунт будет активирован, пополняйте баланс и создайте первую карту.",
      text2:
        "Теперь вы можете настроить и запустить свои рекламные кампании где угодно!",
      users: {
        text1: "Присоединяйтесь к <strong>более чем</strong>",
        text2: "пользователей multicards.io",
        count: "10.5k+",
      },
      list: {
        item1: {
          title: "Пополните <br>свой баланс",
          subtitle:
            "Удобные способы пополнения баланса с помощью Wire или криптовалют.",
        },
        item2: {
          title: "Управляйте своими кампаниями",
          subtitle:
            "Наш удобный интерфейс позволит запустить свою кампанию легко и эффективно.",
        },
        item3: {
          title: "Анализируйте детальную историю транзакций",
          subtitle:
            "У вас будет доступна полная история транзакций с возможностью экспорта данных.",
        },
      },
    },
    benefits: {
      title: "Преимущества Multicards.io",
      list: {
        item1: {
          title: "Трастовые карты",
          text: "Более <b>30 BINов</b> с безлимитным и моментальным выпуском карт.",
        },
        item2: {
          title: "Лимитные карты",
          text: "Управляйте своими дневными и общими лимитами трат или используйте в качестве лимита текущий баланс аккаунта.",
        },
        item3: {
          title: "Карты для ваших нужд",
          text: "Наши карты предназначены для работы с Facebook, Google, TikTok и множеством других рекламных платформ.",
        },
        item4: {
          title: "Детальная обработка данных",
          text: "Вы увидите все транзакции в реальном времени в вашем кабинете.",
        },
        item5: {
          title: "Поддержка",
          text: "В любое время, вне зависимости от времени работы банка.",
        },
        item6: {
          title: "Защищено на 100%",
          text: "Двухфакторная аутентификация и контроль расходов для вас и вашей команды.",
        },
        item7: {
          title: "200+",
          text: "Сотрудников в команде",
        },
      },
    },
    contact_us: {
      title: "Связаться с нами",
      subtitle:
        "Если у вас есть любые вопросы, вы всегда можете <span>обратиться</span> к нашей команде <span>поддержки</span> и мы расскажем гораздо больше о наших <span>преимуществах</span>.",
    },
    partners: {
      subtitle: "Наши партнеры",
    },
    footer: {
      title: "Присоединяйтесь!",
      subtitle:
        "Подписывайтесь на наши рассылки и будьте в курсе о наших последних новостях.",
      follow_us: "Подпишитесь:",
      terms_of_use: "Условия использования",
      privacy_policy: "Политика конфиденциальности",
      copyright:
        "© {0} Multicards. Все права защищены. DIGITORY GROUP PC, Соединённое Королевство.",
    },
  },
  login: {
    title: "Вход в аккаунт",
    subtitle: "Введите вашу электронную почту и пароль, чтобы продолжить",
    dont_have_account: "У вас нет аккаунта?",
    join: "Присоединяйтесь к Multicards.io",
    intro: {
      title: "Добро пожаловать на multicards.io!",
      subtitle:
        "Следуйте этим простым шагам, чтобы войти в свою учетную запись.",
    },
  },
  sign_up: {
    title: "Регистрация аккаунта",
    subtitle: "Введите свои данные, чтобы создать аккаунт",
    have_account: "У вас уже есть аккаунт?",
    login: "Авторизоваться",
    register: "Зарегистрироваться",
    intro: {
      title: "Присоединяйтесь",
      subtitle: "Пройдите регистрацию за несколько шагов.",
    },
  },
  forgot_password: {
    title: "Забыли свой пароль?",
    send_code_again: "Отправить код повторно",
    intro: {
      title: "Восстановление пароля",
      subtitle:
        "Введите адрес электронной почты, связанный с вашей учетной записью. Мы отправим вам письмо с инструкциями по сбросу пароля.",
    },
  },
  verify_account: {
    title: "Здравствуйте!",
    text1: `Мы рады, что вы зарегистрировались на <a href="${ORIGIN}" target="_blank">multicards.io</a>!`,
    text2: `Чтобы начать пользоваться <a href="${ORIGIN}" target="_blank">multicards.io</a>, нам необходимо верифицировать ваш аккаунт. Для этого свяжитесь, пожалуйста, с нами через telegram:`,
  },
  confirm_email: {
    title: "Подтвердите почту для аккаунта",
    text1: `Добро пожаловать на сервис <a href="${ORIGIN}" target="_blank">multicards.io</a>!`,
    text2:
      "Мы выслали вам ссылку для подтверждения аккаунта на вашу почту: <b>{0}</b>",
    resend_link: "Повторно отправить ссылку",
  },
  subscribe: "Подписаться",
  blocked_account: {
    title: "Здравствуйте!",
    text1:
      "Ваш аккаунт был заблокирован. Сейчас вы не сможете войти и пользоваться этим аккаунтом",
    text2: "Вы всегда можете связаться с нами через telegram:",
  },
  feedback_survey: {
    title: "Ваше мнение важно для нас!",
    skip_survey: "Пропустить опрос",
    send_answer: "Отправить ответ",
    completion: {
      title: "Спасибо за ваш ответ!",
    },
    question_1: {
      text: `С баланса каких Партнерских Программ ты бы хотел иметь возможность вывода денежных средств для<br>пополнения <a href="${ORIGIN}" tabindex="-1" target="_blank">multicards.io</a>?`,
    },
    question_2: {
      text: `Какую платежную систему ты используешь помимо <a href="${ORIGIN}" tabindex="-1" target="_blank">multicards.io</a>?`,
    },
  },
  dashboard: {
    my_balance: "Мой баланс",
    contact_support: "Свяжитесь с нами",
    main_product_tour: {
      buttons: {
        lets_start: "Давайте начнем!",
        skip_intro: "Пропустить обучение",
        finish_intro: "Закончить обучение",
        move_to_cards: `Перейти к разделу "Карты"`,
        move_to_balance_cards: `Перейти к разделу "Балансовые карты"`,
        move_to_limit_cards: `Перейти к разделу "Лимитные карты"`,
        move_to_refill: 'Перейти к разделу "Пополнение"',
        move_to_currency_converter: `Перейти к разделу "Конвертация"`,
        move_to_transactions: `Перейти к разделу "Транзакции"`,
        move_to_cash_flow: `Перейти к разделу "Поток средств"`,
        move_to_team: `Перейти к разделу "Команда"`,
        move_to_statistic: `Перейти к разделу "Статистика"`,
        move_to_profile: `Перейти к разделу "Профиль"`,
        move_to_additional_info: `Перейти к разделу "Доп. информация"`,
        watch_tutorial: "Пройти обучение",
      },
      sure_skip_intro: "Уверены, что хотите пропустить обучение?",
      step1: `
        Мы рады, что вы решили воспользоваться сервисом multicards.io.<br /><br />
        Мы подготовили для вас обучение по нашему сервису. Здесь мы расскажем коротко о всем функционале нашего сервиса.<br /><br />
        Уверены, что вам он понравится и вы останетесь довольны 🙂
      `,
      step2: `
        Сверху вы всегда будете видеть баланс вашего аккаунта.<br /><br />

        Обратите внимание, что мы разделили для вашего удобства балансы на:<br />
        - Основной баланс<br />
        - Карточный баланс (Денежные средства, которые лежат на картах)
      `,
      step3: `
        Здесь же находится общий показатель отклоненных платежей - Decline rate.<br /><br />
        Данный блок всегда поможет вам понять вашу статистику по отклоненным платежам.
      `,
      step4: `
        В разделе “Карты”, вы можете создавать как балансовые, так и лимитные карты.<br /><br />
        Изначально доступны только балансовые карты. Для открытия лимитных карт обратитесь к вашему аккаунт-менеджеру.
      `,
      step5:
        "В данном разделе есть поле для поиска карт по названию, настройка колонок, фильтрация карт по владельцу, БИН-у и статусу.",
      step6: "Вы можете сделать выгрузку по картам в двух форматах: CSV и XLS.",
      step7: `Для выпуска карты следует нажать на кнопку "Выпуск карты".`,
      step8:
        "В данном окне отображаются доступные для выпуска карты и основная информация по ним.",
      step9:
        "Далее вы можете выбрать любой подходящий вам БИН и попробовать выпустить карту.",
      step10:
        "В окне создания карты необходимо ввести название карты, сумму пополнения и общее количество карт.",
      step11: "Ниже вы увидите общую сумму операции по выпуску карт.",
      step12: "Условия для данного БИН-а указаны в данном блоке.",
      step13:
        "После создания балансовой карты вы можете увидеть основную информацию по ней: номер карты, CVC, статус, текущий баланс и расход.",
      step14:
        "Вы всегда можете пополнить баланс или снять денежные средства с балансовой карты.",
      step15: `Также есть удобная функция - "Автопополнение". С помощью данной функции вы можете настроить автоматическое пополнение балансовой карты.`,
      step16:
        "Около каждой карты есть бургер-меню, где скрывается дополнительный функционал.",
      step17: "Функция быстрого просмотра всех транзакций по данной карте.",
      step18: "Функция быстрого просмотра всей статистики по данной карте.",
      step19: `Функция "Пауза" для карты.`,
      step20: `Функция "Закрыть карту". После закрытия все денежные средства на карте вернутся на основной баланс.`,
      step21: ` 
        Похожий функционал кроется в разделе "Лимитные карты".<br />
        Главное различие - это то, что все транзакции списывают денежные средства с основного баланса.<br /><br />
        
        Карта настраивается через 2 значения:
        - Дневной лимит
        - Общий лимит
      `,
      step22: "Лимит карты настраивается вручную.",
      step23: `
        Здесь вы можете настроить лимиты на текущей карте или привязать данные лимиты к балансу аккаунта.<br /><br /><br />

        Если выставить значения "Без дневного лимита", то значение "Дневной лимит" будет приравниваться к балансу аккаунта.<br /><br />
        Если выставить значения "Без общего лимита", то значение "Общий лимит" будет приравниваться к балансу аккаунта.
      `,
      step24:
        'Во вкладке "Пополнение" вы всегда можете пополнить баланс вашего аккаунта.',
      step25: "Для пополнения доступны опции USDT и Capitalist.",
      step26:
        "Мы рекомендуем пользоваться пополнением USDT TRC20, так как данный способ выгоднее, удобнее и быстрее.",
      step27:
        "В данном окне вы можете воспользоваться калькулятором и точно определить сумму, которая упадет на баланс.",
      step28:
        "Обязательно копируйте данный кошелек, именно он привязан к вашему аккаунту!",
      step29: `В подразделе “Конвертер” осуществляется конвертация денежных средств основного баланса.`,
      step30: "Доступны конвертации USD ⭢ EUR и EUR ⭢ USD.",
      step31: `В разделе "Транзакции" вы увидите все операции, которые касаются карт и карточного баланса.`,
      step32: `
        Сверху расположены основные фильтры:<br /><br />
        - Фильтр по дате<br />
        - Фильтр по пользователям<br />
        - Фильтр по картам
      `,
      step33: `
        Дополнительные фильтры расположены здесь:<br /><br />
        - Фильтр по валюте<br />
        - Фильтр по статусу<br />
        - Фильтр по типу транзакции
      `,
      step34: "Также доступна функция выгрузки отчета в CSV и XLS.",
      step35: "Пример транзакции.",
      step36: `
        В разделе "Поток средств" вы увидите все операции, которые касаются вашего основного баланса.<br /><br />

        Список всех операций:<br />
        - Конвертация валюты<br />
        - Комиссия за транзакцию<br />
        - Пополнение внутреннего баланса<br />
        - Пополнение карты<br />
        - Перевод с карты на основной баланс<br />
        - Перевод денежных средств<br />
        - Входящий перевод денежных средств<br />
        - Комиссия за создание карты<br />
        - Комиссия за отклоненный платеж<br />
        - Возврат по транзакции<br />
        - Возврат по транзакции с закрытой карты<br />
        - Закрытие карты. Возврат неиспользованных средств
      `,
      step37: `
        Сверху расположены основные фильтры:<br /><br />
        - Фильтр по дате<br />
        - Фильтр по пользователям
      `,
      step38: `
        Дополнительные фильтры расположены здесь:<br /><br />
        - Фильтр по валюте<br />
        - Фильтр по типу операции
      `,
      step39: "Также доступна функция выгрузки отчета в CSV и XLS.",
      step40: "Пример операции.",
      step41: `
        В разделе “Команда” осуществляется управление вашей командой.<br /><br />
        Для доступа к командному интерфейсу обратитесь к своему аккаунт-менеджеру.
      `,
      step42: `
        Здесь расположена пригласительная ссылка, которую вам нужно прислать вашему медиабайеру. Он автоматически прикрепится в вашу команду после регистрации.<br /><br />
        Ссылку можно скопировать или сгенерировать заново.
      `,
      step43:
        "Для каждого члена команды вы можете настроить лимит на количество активных карт.",
      step44:
        "Вы можете сделать пополнение своего медиабайера в данном разделе.",
      step45: "Также доступна функция автоматического пополнения медиабайера.",
      step46: `
        Вы всегда можете удалить медиабайера из команды.<br /><br />
        Обратите внимание, что все его карты автоматически закроются, а остаток баланса будет переведен на ваш аккаунт.
      `,
      step47: `В разделе “Статистика” отображается вся сводная информация о транзакциях по оплатам, холдам, поступлениям, возвратам и комиссиям.`,
      step48: `
        Сверху можно найти разные фильтры для вашего удобства:<br />
        - Фильтр по дате<br />
        - Фильтр по пользователям<br />
        - Фильтр по картам<br />
        - Фильтр по банкам<br />
        - Фильтр по валюте
      `,
      step49: `
        Особое внимание стоит уделить группировке.<br /><br />
        Данный отчет можно группировать как по членам команды, так и по дате.
      `,
      step50: "Также доступна функция выгрузки отчета в CSV и XLS.",
      step51: `Раздел "Профиль" содержит основную информацию по вашему аккаунту и несколько дополнительных функций.`,
      step52:
        "Вы можете привязать номер телефона к аккаунту для того, чтобы пользоваться БИН-ами с 3ds.",
      step53: `
        В целях безопасности есть возможность подключить 2FA.<br /><br />
        Рекомендуем использовать данный метод защиты вашего аккаунта.
      `,
      step54: `
        Также есть возможность выключить ограничение на 1 активную сессию на вашем аккаунте.<br /><br />
        Рекомендуем выключать лимит только при активном 2FA.
      `,
      step55: `
        Нажатие на эту кнопку перенаправит вас на support-бота сервиса Multicards.io.<br /><br />
        Наши менеджеры поддержки в кратчайшие сроки поможет решить любой вопрос касаемо работы сервиса.
      `,
      step56:
        "В разделе F.A.Q. есть ответы на все основные вопросы по работе с сервисом и его условия.",
      step57: `
        Здесь публикуются все важные новости multicards.io.<br /><br />
        В данном разделе вы всегда сможете увидеть актуальную информацию.
      `,
      step58: `
        Здесь наш краткий гайд подошел к концу!<br /><br />
        Мы стараемся делать все, чтобы вам было удобно работать с виртуальными картами.<br />
        Вы всегда можете написать в нашу поддержку или вашему личному аккаунт-менеджеру по любому вопросу!
      `,
    },
    users: {
      title: "Пользователи",
    },
    change_card_balance: {
      title: "Изменение баланса",
      from: "Откуда",
      to: "Куда",
    },
    auto_refill: {
      title: "Автопополнение",
      enabled: "Автопополнение включено",
      disabled: "Автопополнение выключено",
      threshold: "Если баланс ниже",
    },
    confirmation_code: {
      request_again_via: "Запросить новый код через {0} с.",
      request_again: "Запросить код повторно",
      get: "Получить код",
    },
    phone_number: {
      approved: "Телефон подтвержден",
      verify_phone: "Подтвердите телефон",
      bind: "Привязать",
      sure_want_unbind: "Вы уверены, что хотите отвязаться от номера {0} ?",
      sure_want_change:
        "Вы уверены, что хотите сменить текущий номер телефона на {0} ?",
      used_for_vidar: `Данный номер телефона используется для рассылки 3ds сообщений. <br /> На данный момент это работает только для Vidar (EUR)`,
    },
    statistic: {
      groupBy: {
        label: "Группировать по",
        date: "Дате",
        mediabuyer: "Медиабайеру",
        teamlead: "Тимлиду",
      },
    },
    welcome: {
      title: "Мы рады, что вы решили воспользоваться нашим сервисом!",
      refill: {
        text: "Первое пополнение до<br /><b>{0}</b> без комиссии<br />специально для тебя!",
      },
      issueCard: {
        text: "Выпуск <b>первых {0} карт</b> бесплатно специально для тебя!",
      },
      support: {
        text: "Присоединяйся к нам в <b>telegram!</b><br />Будь в курсе свежих новостей.",
      },
    },
    user: {
      role: {
        accountant: "Аккаунтер",
        teamlead: "Тимлид",
        owner: "Owner",
        mediabuyer: "Медиабайер",
      },
    },
    limit_per_active_session: "Лимит на 1 активную сессию",
    encrypt_card_data: "Шифровать данные карт",
    facebook_code_notifications: "Уведомлять по кодам Facebook",
    hide_team_members_cards: "Скрывать карты команды",
    change_profile_image: "Изменение фотографии профиля",
    avatar_objects: "Предметы",
    avatar_animals: "Животные",
    avatar_people: "Люди",
    datepicker: {
      today: "Сегодня",
      yesterday: "Вчера",
      last_3_days: "Последние 3 дня",
      last_7_days: "Последние 7 дней",
      last_14_days: "Последние 14 дней",
      current_month: "Этот месяц",
      last_month: "Последний месяц",
      prev_month: "Прошлый месяц",
      last_3_months: "Последние 3 месяца",
      last_6_months: "Последние 6 месяцев",
      custom: "Пользовательский",
    },
    news: {
      title: "Новости",
      show_more: "Развернуть",
      show_less: "Свернуть",
    },
    card: {
      batch_issue: {
        comming_soon: `Ваши карты уже почти готовы!<br /> Они появятся в разделе {icon} <b>"Карты"</b> в скором времени.`,
        pending: 'Группа карт <b>"{0}"</b> создается. Пожалуйста, подождите...',
        success: 'Группа карт <b>"{0}"</b> создана!',
        confirm_cancel: {
          title: 'Вы уверены, что хотите отменить выпуск группы карт "{0}" ?',
        },
      },
      batch_update: {
        pending: {
          close_cards: "{0} / {1} карт закрыто.",
          freeze_cards: "{0} / {1} карт поставлено на паузу.",
          unfreeze_cards: "{0} / {1} карт снято с паузы.",
          unsuspend_cards: "{0} / {1} карт разморожено.",
        },
        success: {
          close_cards: "{0} {1} успешно {2}!",
          freeze_cards: "{0} {1} успешно {2}!",
          unfreeze_cards: "{0} {1} успешно {2}!",
          unsuspend_cards: "{0} {1} успешно {2}!",
        },
        are_you_sure: {
          close_cards: "Вы уверены, что хотите<br />закрыть {0} {1}?",
          freeze_cards:
            "Вы уверены, что хотите<br />поставить на паузу {0} {1}?",
          unfreeze_cards: "Вы уверены, что хотите<br />снять с паузы {0} {1}?",
          unsuspend_cards: "Вы уверены, что хотите<br />разморозить {0} {1}?",
          cancel_action: "Вы уверены, что хотите отменить действие?",
        },
      },
    },
    qrcode: {
      connect_info:
        "Наведите на QR-код камеру телефона из приложения Google Authenticator и введите полученный код",
    },
    billing: {
      status: {
        created: "Создано",
        success: "Успешно",
        error: "Ошибка",
        expired: "Истёк",
      },
      usdt_transfer: {
        title:
          "Вы можете пополнить свой счет путем перевода USDT по данным реквизитам.",
        text1: `
          Инструкция по пополнению:<br/>
          1. Скопировать адрес пополнения<br/>
          2. Сделать пополнение по соответствующим реквизитам<br/>
          3. Написать в поддержку <a href="${telegramSupportBotLink}" target="_blank">@multicards_support_bot</a><br/>
          4. В обязательном порядке прислать менеджеру <b>хэш транзакции</b> (идентификатор транзакции)<br/>
        `,
        text2: `
          Обращаем внимание, что Ваш счет не будет пополнен, пока мы не получим <b>подтверждение транзакции</b>.<br/>
          Минимальное пополнение составляет {0} USDT.
        `,
        amount_out: "Отдаете {0}",
        amount_in: "Получаете {0}",
        address_wallet: "Адрес кошелька",
        refill_commission: "Комиссия на пополнение составляет {0}% + {1} USDT",
        min_amount: "Минимальная сумма пополнения {0} USDT",
        warning:
          "<b>Внимательно</b> проверьте, что введенный Вами кошелек совпадает с реквизитами. Переведенная сумма будет начислена Вам в течение 🕒 15-30 минут.",
      },
    },
    refunded: "Возвращено",
    selected_mediabuyer_from_to: "Медиабайеров {0} / {1}",
    selected_from_to: "Выбрано {0} / {1}",
    selected_count_label_1: "Выбрана {0} {1}",
    selected_count_label_2: "Выбрано {0} {1}",
    hide: "Скрыть",
    view_cards: "Посмотреть карты",
    back_to_cards: "Вернуться к картам",
    show_all_cards: "Показать все карты",
    choose_bank: "Выберите банк",
    choose_or_add_card_group: "Выберите или добавьте новую группу",
    amount_debited_from_your_balance:
      "Сумма, которая будет списана с вашего баланса",
    transfer_from: "От",
    transfer_to: "Кому",
    set: "Установить",
    set_limit: "Установить лимит",
    money_transaction: "Перевод средств",
    delete: "Удалить",
    activate: "Восстановить",
    to_refill: "Пополнить",
    transfer: "Перевод",
    team_statistics: "Статистика по команде",
    lang: "Язык",
    refill: "Пополнение",
    close_card: "Закрыть карту",
    pause_card: "Пауза",
    play_card: "Снять с паузы",
    unfreeze_card: "Разморозить карту",
    unfreeze: "Разморозить",
    issue_card: "Выпуск карты",
    issue_limit_card: "Выпуск лимитной карты",
    filters: "Фильтры",
    settings: "Настройки",
    table_columns: "Колонки в таблице",
    clear: "Очистить",
    clear_all: "Очистить все",
    choose_all: "Выбрать все",
    search: "Поиск",
    card_n: "Карта {0}",
    submit: "Отправить",
    show: "Показать",
    show_all: "Показать все",
    close: "Закрыть",
    accept: "Принять",
    save: "Сохранить",
    cancel: "Отмена",
    yes: "Да",
    no: "Нет",
    card_deposit: "Пополнение карты",
    transfer_to_balance: "Перевод на баланс",
    bank_info: {
      odin: {
        auto_closed_during_one_month:
          "Обратите внимание, что карты на данном БИН-е автоматически закрываются, если не было транзакций в течение месяца.",
      },
    },
    transaction: {
      status: {
        approved: "Одобрено",
        settled: "Урегулированно",
        declined: "Отклонено",
        pending: "В ожидании",
      },
      type: {
        payment: "Оплата",
        refund: "Возврат",
        unknown: "Остальные",
        authorization: "Авторизация",
        refill: "Поступление",
        commission: "Комиссия",
      },
      cardType: {
        prepaid: "Балансовые",
        postpaid: "Лимитные",
      },
    },
    cash_flow: {
      type: {
        funds_transfer: "Перевод денежных средств",
        card_issue: "Комиссия за создание карты",
        transaction_fee: "Комиссия за транзакцию",
        int_transaction_fee: "Комиссия за интернациональную транзакцию",
        card_deposit: "Пополнение карты",
        funds_deposit: "Пополнение внутреннего баланса",
        incoming_funds_transfer: "Входящий перевод денежных средств",
        transaction_refund: "Возврат по транзакции",
        funds_exchange: "Конвертация валюты",
        decline_fee: "Комиссия за отклоненный платеж",
        card_close: "Закрытие карты. Возврат неиспользованных средств",
        transaction_refund_to_balance: "Возврат по транзакции с закрытой карты",
        transaction_refunded_fee:
          "Компенсация комиссии за возвращенные платежи",
        card_withdraw: "Перевод с карты на основной баланс",
      },
    },
    currency_converter: {
      title: "Конвертер валют",
      from_currency: "Из валюты",
      to_currency: "В валюту",
      amount: "Сумма",
      receive_amount: "Сумма, которую вы получите",
      exchange_rate_valid: "Обменный курс действителен {0}с",
      convert: "Конвертировать",
      are_you_sure: `Вы уверены, что хотите конвертировать <span class="text-no-wrap">{0} ⭢ {1} ?</span>`,
      successful_conversion: "Конвертация прошла успешна",
    },
    table: {
      header: {
        on_hold: "На удержании",
        from: "Из",
        to: "В",
        closed_by: "Закрыто",
        income: "Поступления",
        comment: "Комментарий",
        role: "Роль",
        bank: "Банк",
        banks: "Банки",
        expire: "Действителен до",
        name: "Имя",
        number_of_cards: "Количество карт",
        number_of_active_cards: "Количество активных карт",
        expenditure: "Расход",
        date: "Дата",
        card_number: "Номер карты",
        issued_at: "Выпущена",
        status: "Статус",
        cardType: "Тип карты",
        owner: "Владелец",
        spend: "Потрачено",
        balance: "Баланс",
        card_balance: "Карточный баланс",
        currency: "Валюта",
        amount: "Сумма",
        transaction_fee: "Комиссия за транзакции",
        another_transaction_fee: "Комиссия за международные транзакции",
        additional_transaction_fee_another_currency:
          "Доп. комиссия за международные транзакции",
        transaction_fee_with_currency: "Комиссия за транзакции ({0})",
        min_transaction_fee: "Мин. комиссия за транзакцию",
        decline_transaction_fee: "Комиссия за отклоненные транзакции",
        fixed_fee_for_any_operation: "Фикс. комиссия за любую операцию",
        commission_rate: "Комиссия",
        commission_rate_for_cards: "Комиссия за карты",
        type: "Тип",
        description: "Описание",
        wallet: "Кошелек",
        account: "Аккаунт",
        limit_amount: "Сумма лимита",
        payment_method: "Платежная система",
        payments: "Оплаты",
        refunds: "Возвраты",
        transactions: "Транзакции",
        transactions_amount: "Транзакции (кол-во)",
        statistic_amount: "Кол-во",
        statistic_decline: "Отклонено",
        statistic_decline_amount: "Отклонено (кол-во)",
        statistic_pending: "Одобрено | В обработке",
        statistic_pending_amount: "Одобрено | В обработке (кол-во)",
        statistic_refunded: "Возвращено",
        statistic_refunded_amount: "Возвращено (кол-во)",
        statistic_approved: "Одобрено | Урегулировано",
        statistic_approved_amount: "Одобрено | Урегулировано (кол-во)",
        declined: "Отклоненные",
        total: "Итого",
        numTransaction: "Число транзакций",
        numDeclinedTransaction: "Количество отклоненных транзакций",
        daily_spend: "Дневной расход",
        daily_limit: "Дневной лимит",
        group_name: "Группа",
        total_spend: "Общий расход",
        total_limit: "Общий лимит",
        bin_name: "Название BIN",
        bin_id: "BIN ID",
        zip_info: "ZIP-информация",
        recommendation: "Рекомендуется",
        supervisor: "Руководитель",
      },
    },
    sidebar: {
      profile: "Профиль",
      cards: "Карты",
      reports: "Отчеты",
      my_balance: "Мой баланс",
      referral_program: "Реф. программа",
      converter: "Конвертер",
      transactions: "Транзакции",
      cash_flow: "Поток средств",
      refill: "Пополнение",
      team: "Команда",
      statistic: "Статистика",
      statistic_by_users: "Пользователи",
      statistic_by_cards: "Карты",
      statistic_by_status: "По статусу",
      statistic_by_type: "По типу",
      support: "Поддержка",
      card_with_limit: "С лимитом",
      card_with_balance: "С балансом",
      partners: "Партнеры",
    },
    profile: {
      notification: {
        by_email: "По email",
        by_telegram: "По Telegram",
      },
    },
    cards: {
      title: "Карты",
      status: {
        active: "Активен",
        closed: "Закрыт",
        freeze: "Пауза",
        suspend: "Заморожено",
      },
    },
    faq: {
      title: "Часто задаваемые вопросы",
    },
  },
};
